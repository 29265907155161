import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    current: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    buttonSize: {
      type: Number,
      default: 3
    },
    color: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      totalSize: 0,
      realButtonSize: 0
    };
  },
  computed: {},
  watch: {
    total: function total(newVal) {
      this.totalSize = Math.ceil(this.total / this.size);
      this.realButtonSize = this.buttonSize < this.totalSize ? this.buttonSize : this.totalSize;
    }
  },
  created: function created() {
    this.totalSize = Math.ceil(this.total / this.size);
    this.realButtonSize = this.buttonSize < this.totalSize ? this.buttonSize : this.totalSize;
  },
  mounted: function mounted() {},
  methods: {
    toPage: function toPage(flag) {
      if (flag === -1) {
        this.$emit("toPage", this.current - 1);
      } else if (flag === -2) {
        this.$emit("toPage", this.current + 1);
      } else {
        this.$emit("toPage", flag);
      }
    }
  }
};